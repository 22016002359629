<template>
  <q-layout view="hHh LpR fFf" class="bg-white">
    <q-page-container>
      <router-component name="appbar" fallback="MMobileAppbar" :loading="loading" />
      <q-page>
        <m-gmaps v-show="showMap" ref="map" :markers="markers" />
        <m-bottom-sheet ref="sheet">
          <keep-alive :include="['Home', 'Bookings', 'Book', 'Journeys', 'Profile']">
            <router-view @loading="loading = $event" @markers="markers = $event" />
          </keep-alive>
        </m-bottom-sheet>
      </q-page>
      <router-component name="footer" fallback="MMobileFooter" />
      <router-view ref="right" name="right" />
      <m-booking-complete />
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import routerComponent from '../router-component/index.vue'
export default {
  components: {
    routerComponent
  },
  data () {
    return {
      markers: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['ui']),
    showMap () {
      return this.$route.meta?.layout?.bottomsheet || this.$route?.meta?.layout?.bottomcards
    }
  },
  mounted () {
    document.body.classList.add('bg-white')
  }
}
</script>

<style lang="stylus" scoped>
.vue-map-container
  position fixed
  top 0
  right 0
  bottom 0
  left 0
.bottom-sheet
  top calc(100vh - 260px)
</style>
